import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchOptions = async (url) => {
  const token = localStorage.getItem("authToken");
  if (!token) {
    throw new Error("No token found in localStorage.");
  }
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

// Async thunks for fetching different options
export const fetchContactOptions = createAsyncThunk(
  "options/fetchContactOptions",
  async () => fetchOptions(process.env.REACT_APP_API_OPTIONS_CONTACTS)
);

export const fetchDealOptions = createAsyncThunk(
  "options/fetchDealOptions",
  async () => fetchOptions(process.env.REACT_APP_API_OPTIONS_DEALS)
);

export const fetchLeadOptions = createAsyncThunk(
  "options/fetchLeadOptions",
  async () => fetchOptions(process.env.REACT_APP_API_OPTIONS_LEADS)
);

export const fetchTaskOptions = createAsyncThunk(
  "options/fetchTaskOptions",
  async () => fetchOptions(process.env.REACT_APP_API_OPTIONS_TASKS)
);

// Slice definition
const optionsSlice = createSlice({
  name: "options",
  initialState: {
    contactOptions: null,
    dealOptions: null,
    leadOptions: null,
    taskOptions: null,
    isLoading: false,
    isError: false,
  },
  reducers: {}, // Reducers for manual state updates (if needed)
  extraReducers: (builder) => {
    // Generic loading state handling
    builder
      .addCase(fetchContactOptions.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchDealOptions.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchLeadOptions.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchTaskOptions.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      });

    // Handling fulfilled states
    builder.addCase(fetchContactOptions.fulfilled, (state, action) => {
      state.contactOptions = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchDealOptions.fulfilled, (state, action) => {
      state.dealOptions = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchLeadOptions.fulfilled, (state, action) => {
      state.leadOptions = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchTaskOptions.fulfilled, (state, action) => {
      state.taskOptions = action.payload;
      state.isLoading = false;
    });

    // Handling rejected states
    builder.addCase(fetchContactOptions.rejected, (state, action) => {
      console.error("Error fetching contact options:", action.error);
      state.isError = true;
      state.isLoading = false;
    });
    builder.addCase(fetchDealOptions.rejected, (state, action) => {
      console.error("Error fetching deal options:", action.error);
      state.isError = true;
      state.isLoading = false;
    });
    builder.addCase(fetchLeadOptions.rejected, (state, action) => {
      console.error("Error fetching lead options:", action.error);
      state.isError = true;
      state.isLoading = false;
    });
    builder.addCase(fetchTaskOptions.rejected, (state, action) => {
      console.error("Error fetching task options:", action.error);
      state.isError = true;
      state.isLoading = false;
    });
  },
});

// Export the reducer
export default optionsSlice.reducer;
