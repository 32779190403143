import React from "react";
import "../Global/global.css";

export default function ComingSoon() {
  return (
    <div
      className="min-h-screen w-full flex flex-col items-center justify-center"
      style={{
        backgroundImage:
          "linear-gradient(to top, #141414, #171717, #1a1a1a, #1c1c1c, #1f1f1f, #232325, #27282b, #2a2d32, #2c363d, #2c3f46, #2d494d, #305350)",
        paddingTop: "60px",
      }}
    >
      <div className="text-center">
        {/* Font Awesome Icon */}
        <i
          className="fas fa-hourglass-half text-6xl text-gray-300 animate-spin-slow"
          style={{ animation: "spin 4s linear infinite" }}
        ></i>

        {/* Coming Soon Text */}
        <h1 className="mt-6 text-4xl font-bold text-gray-100 animate-pulse">
          Coming Soon
        </h1>

        {/* Subtitle */}
        <p className="mt-4 text-gray-400">
          We're working hard to bring this feature to life.
        </p>
      </div>
    </div>
  );
}
