// slices/tasksSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch Tasks Based on User Role
export const fetchTasks = createAsyncThunk(
  "tasks/fetchTasks",
  async (_, { getState }) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      throw new Error("No token found");
    }

    const { user } = getState().user; // Use `.user` to match the store structure
    const url =
      user.role === "admin"
        ? process.env.REACT_APP_API_TOTAL_TASKS
        : process.env.REACT_APP_API_TASKS;

    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.tasks;
  }
);

const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    isLoading: false,
    tasks: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tasks = action.payload;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching tasks:", action.error.message);
      });
  },
});

export default tasksSlice.reducer;
