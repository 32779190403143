// slices/dealsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch Deals Based on User Role
export const fetchDeals = createAsyncThunk(
  "deals/fetchDeals",
  async (_, { getState }) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      throw new Error("No token found");
    }

    const { user } = getState().user; // Use `.user` to match the store structure
    
    const url =
      user.role === "admin"
        ? process.env.REACT_APP_API_TOTAL_DEALS
        : process.env.REACT_APP_API_DEALS;

    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.deals;
  }
);

const dealsSlice = createSlice({
  name: "deals",
  initialState: {
    isLoading: false,
    deals: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDeals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deals = action.payload;
      })
      .addCase(fetchDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching deals:", action.error.message);
      });
  },
});

export default dealsSlice.reducer;
