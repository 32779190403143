import React, { useEffect, useState } from "react";
import "../Pages/taskpg.css";

export default function Blogs() {
  const [currentTime, setCurrentTime] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedTime = now.toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "short",
      });
      setCurrentTime(formattedTime);
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  return (
    <>
      <section className="mainTaskSection">
        {/* Topbar */}
        <div className="topbar_main_tsk">
          {" "}
          <h1 className="text-xl font-bold text-gray-800">
            <i className="fas fa-book-open text-2xl px-2"></i>
            Welcome to Our Blogging Hub
          </h1>
          <div className="topbar_right">
            <div className="date_time">
              <i className="fa-solid fa-clock"></i>{" "}
              <span>{currentTime ? currentTime : "loading..."}</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
