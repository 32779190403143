// slices/leadsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch Leads Based on User Role
export const fetchLeads = createAsyncThunk(
  "leads/fetchLeads",
  async (_, { getState }) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      throw new Error("No token found");
    }

    const { user } = getState().user; // Use `.user` to match the store structure

    const url =
      user.role === "admin"
        ? process.env.REACT_APP_API_TOTAL_LEADS
        : process.env.REACT_APP_API_LEADS;

    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.leads;
  }
);

const leadsSlice = createSlice({
  name: "leads",
  initialState: {
    isLoading: false,
    leads: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeads.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leads = action.payload;
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching leads:", action.error.message);
      });
  },
});

export default leadsSlice.reducer;
