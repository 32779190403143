import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUsersDetails = createAsyncThunk(
  "fetchUsersDetails",
  async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    // Send the request with proper headers
    const response = await axios.get(process.env.REACT_APP_API_USERS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Return the company data directly without using .json()
    return response.data.users;
  }
);

const usersSlice = createSlice({
  name: "usersSlice",
  initialState: {
    isLoading: false,
    users: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsersDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    });
    builder.addCase(fetchUsersDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsersDetails.rejected, (state, action) => {
      console.log("Error", action.error); // Log the error for debugging
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default usersSlice.reducer;
