import React, { useState } from "react";
import "./signInPage.css";
import logo from "../Assets/mainLogo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function SignInPage() {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const userData = {
    userEmail,
    userPassword,
  };

  const handleSubmit = () => {
    setLoading(true); // Show spinner before making the API call

    axios
      .post(process.env.REACT_APP_API_SIGN_IN, userData)
      .then((response) => {
        console.log("Login successful:", response.data);

        // Store the token in localStorage
        localStorage.setItem("authToken", response.data.token);

        // After 2 seconds, navigate to the dashboard
        setTimeout(() => {
          navigate("/dashboard");
          window.location.reload();
          setLoading(false); // Hide spinner after navigation
        }, 2000); // 2-second delay
      })
      .catch((error) => {
        console.error("Error signing in:", error.response);
        setLoading(false); // Hide spinner on error

        // Show appropriate error message from backend
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          alert("Incorrect Username or Password");
        } else {
          alert("An unknown error occurred. Please try again later.");
        }
      });
  };

  return (
    <>
      <section
        className={`SignInPage_MainSection ${loading ? "loading-active" : ""}`}
      >
        <div className="loginForm">
          <div className="rightDiv_formDiv_SignInSection">
            <div className="icon_Div_Logo">
              <img src={logo} alt="x360 Marketing Logo Icon" />
              <h1>
                Welcome to <span style={{ color: "#ed813b" }}>Venture </span>CRM
                + CMS Solution For Your Business
              </h1>
              <p>Please login with your credentials to start your adventure</p>
            </div>
            <div className="formDiv">
              <div className="inputFieldDiv">
                <label htmlFor="">Username</label>
                <input
                  onChange={(e) => setUserEmail(e.target.value)}
                  placeholder="Enter Username"
                  type="text"
                />
              </div>

              <div className="inputFieldDiv">
                <label htmlFor="">Password</label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                  type="password"
                />
              </div>

              <div className="rememberMe_Div">
                <span>
                  <input type="checkbox" />
                  <label htmlFor="">Remember Me</label>
                </span>

                <Link>Forgot Password?</Link>
              </div>

              <div className="buttonDiv_form">
                <button onClick={handleSubmit} disabled={loading}>
                  Login
                </button>
              </div>
              <span className="newToPlatForm_span">
                New to our platform?{" "}
                <Link to={"/create/passcode"}>Create an Account</Link>
              </span>

              <span
                style={{
                  textAlign: "center",
                  marginTop: "25px",
                  color: "rgb(80,80,80)",
                  fontSize: "12px",
                  fontWeight: "300",
                }}
              >
                This site is protected by reCAPTCHA and the Google{" "}
                <span style={{ textDecoration: "underline" }}>
                  Privacy Policy
                </span>{" "}
                and{" "}
                <span style={{ textDecoration: "underline" }}>
                  Terms of Service
                </span>{" "}
                apply.
              </span>
            </div>
          </div>
        </div>
      </section>

      {/* Loading spinner overlay */}
      {loading && (
        <div className="loadingSpinnerOverlay_signIn">
          <i
            className="fas fa-spinner fa-spin"
            style={{ color: "#f34100", fontSize: "2em", marginTop: "10px" }}
          ></i>
        </div>
      )}
    </>
  );
}
