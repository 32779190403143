import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUserDetails = createAsyncThunk(
  "fetchUserDetails",
  async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    // Send the request with proper headers
    const response = await axios.get(process.env.REACT_APP_API_USER, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Return the company data directly without using .json()
    return response.data.user;
  }
);

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    isLoading: false,
    user: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      console.log("Error", action.error); // Log the error for debugging
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default userSlice.reducer;
