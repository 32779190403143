import { configureStore } from "@reduxjs/toolkit";
import companyReducer from "./slices/companySlice";
import userReducer from "./slices/userSlice";
import usersReducer from "./slices/usersSlice";
import leadsReducer from "./slices/leadsSlice";
import dealsReducer from "./slices/dealsSlice";
import contactsReducer from "./slices/contactsSlice";
import tasksReducer from "./slices/tasks";
import optionsReducer from "./slices/optionsSlice";

const store = configureStore({
  reducer: {
    company: companyReducer,
    user: userReducer,
    users: usersReducer,
    contacts: contactsReducer,
    leads: leadsReducer,
    deals: dealsReducer,
    tasks: tasksReducer,
    options: optionsReducer,
  },
});

export default store;
