import React, { useEffect, useRef, useState } from "react";
import "./topbar.css";
import ownImg from "../Images/ownImg.jpeg";
import { Link, useNavigate } from "react-router-dom";
import Taskview from "./Taskview";
import CreateDeal from "./CreateDeal";
import CreateLead from "./CreateLead";
import CreateContact from "./CreateContact";
import GlobalSearch from "./GlobalSearch";
import CrmSettingsComp from "./CrmSettingsComp";

export default function NavigationTop() {
  const [isShowSettings, setIsShowSettings] = useState(false);
  const [isShowSettingsMenu, setIsShowSettingsMenu] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const handleLogout = () => {
    setIsLoading(true); // Start loading
    setTimeout(() => {
      localStorage.clear(); // Clear storage
      navigate("/"); // Navigate to homepage
      window.location.reload(); // Reload page
    }, 1000); // 2-second delay
  };

  const [crtDiv, setcrtDiv] = useState(false);
  const containerRef = useRef();
  const [taskCls, setTskCls] = useState("taskView_div_hide");
  const [isViewDeal, setIsViewDeal] = useState(false);
  const [crtLD_CLS, setCrt_CLS] = useState("leads_crt_div_hide");
  const [isViewContact, setIsViewContact] = useState(false);
  const navigate = useNavigate(); // Hook to get history for navigation

  const toggleDiv = () => {
    setcrtDiv((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the container
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setcrtDiv(false);
        setIsShowSettingsMenu(false);
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Function to close the Taskview
  const handleCloseTaskView = () => {
    setTskCls("taskView_div_hide");
  };

  const [isShowComponent, setIsShowComponent] = useState(false);
  return (
    <>
      <CrmSettingsComp
        isShowSettings={isShowSettings}
        setIsShowSettings={setIsShowSettings}
      />
      <GlobalSearch
        setIsShowComponent={setIsShowComponent}
        isShowComponent={isShowComponent}
      />
      <CreateContact
        setIsViewContact={setIsViewContact}
        isViewContact={isViewContact}
        mode="new"
      />

      {isLoading ? (
        <div className="spinnerDiv">
          <div className="spinner"></div>
          <p>Logging out...</p>
        </div>
      ) : null}

      {/* Create Lead Division */}
      <div className={crtLD_CLS}>
        <div className="insertDiv">
          <div className="topbar_insrtLD">
            <h3>Create Lead</h3>
            <span
              onClick={() => {
                setCrt_CLS("leads_crt_div_hide");
              }}
              title="Close"
            >
              <i className="fa-solid fa-xmark fa-lg"></i>
            </span>
          </div>
          {/* Main Division */}
          <CreateLead />
        </div>
      </div>
      {/* Create Lead Division */}
      <CreateDeal
        setIsViewDeal={setIsViewDeal}
        isViewDeal={isViewDeal}
        mode="new"
      />
      <div className={taskCls}>
        <Taskview mode="new" onClose={handleCloseTaskView} />
      </div>
      <section className="mainSectionTopNav" ref={containerRef}>
        <div className="createBTN">
          <span onClick={toggleDiv}>
            <i className="fa-solid fa-circle-plus fa-lg plusICON"></i>
            Create
          </span>
          {crtDiv ? (
            <div
              className="crt-dv-glbl"
              style={{ position: "absolute", top: "40px", left: "110px" }}
            >
              <li
                onClick={() => {
                  setcrtDiv(false);
                  setTskCls("taskView_div_show");
                }}
              >
                <i className="fa-solid fa-list-check"></i>Tasks
              </li>
              <li
                onClick={() => {
                  setCrt_CLS("leads_crt_div_show");
                }}
              >
                <i className="fa-solid fa-user"></i>Lead
              </li>
              <li
                onClick={() => {
                  setIsViewDeal(true);
                }}
              >
                <i className="fa fa-tag"></i>Deal
              </li>
              <li
                onClick={() => {
                  setIsViewContact(true);
                }}
              >
                <i className="fa-solid fa-address-book"></i>Contact
              </li>
            </div>
          ) : null}
        </div>

        <div className="searchDiv">
          <input
            onFocus={() => {
              setIsShowComponent(true);
            }}
            type="text"
            placeholder="Search across Leads, Deals, Contacts, Tasks..."
          />
        </div>

        <div className="accountDet_topbar">
          <i className="fa-solid fa-question fa-xs" />
          <Link
            onClick={() => {
              if (isShowSettingsMenu) {
                setIsShowSettingsMenu(false);
              } else {
                setIsShowSettingsMenu(true);
              }
            }}
          >
            <img
              className="h-10"
              title="User profiles & Settings"
              src={ownImg}
              alt="User Profile"
              height={"35px"}
            />
            <i
              title="User profiles & Settings"
              className="fa-solid fa-angle-down fa-sm"
            ></i>
            {isShowSettingsMenu ? (
              <div className="user-profiles-div-topbar">
                <ul>
                  <li
                    onClick={() => {
                      setIsShowSettings(true);
                    }}
                  >
                    <i className="fas fa-cog"></i> Settings
                  </li>
                  <li onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt"></i> Log Out
                  </li>
                </ul>
              </div>
            ) : null}
          </Link>
        </div>
      </section>
    </>
  );
}
