import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import "./index.css"; // CSS ka path correct hona chahiye

import SignInPage from "./Pages/SignInPage";
import CreateAc from "./Pages/CreateAc";
import CRT_USR_INFR from "./Pages/CRT_USR_INFR";
import CRT_PSCD from "./Pages/CRT_PSCD";
import Successpage from "./Pages/Successpage";
import Dashboard from "./Pages/Dashboard";
import Leads from "./Pages/Leads";
import NavigationBar from "./Components/NavigationBar";
import NavigationTop from "./Components/NavigationTop";
import Taskspage from "./Pages/Taskspage";
import Deals from "./Pages/Deals";
import Contacts from "./Pages/Contacts";
import Invoices from "./Pages/Invoices";
import Inbox from "./Pages/Inbox";
import Blogs from "./Pages/Blogs";

function App() {
  // Check if auth token exists in localStorage
  const authToken = localStorage.getItem("authToken");
  console.log(authToken);

  return (
    <BrowserRouter>
      <section className="App">
        {authToken ? (
          <>
            <NavigationBar />
            <NavigationTop />
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/leads" element={<Leads />} />
              <Route path="/deals" element={<Deals />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/tasks" element={<Taskspage />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/comingsoon" element={<Inbox />} />
            </Routes>{" "}
          </>
        ) : (
          <Routes>
            <Route path="/dashboard" element={<Navigate to="/" />} />
            <Route path="/" element={<SignInPage />} />
            <Route path="/create/companyinformation" element={<CreateAc />} />
            <Route path="/create/userinformation" element={<CRT_USR_INFR />} />
            <Route path="/create/passcode" element={<CRT_PSCD />} />
            <Route path="/success-page" element={<Successpage />} />
          </Routes>
        )}
      </section>
    </BrowserRouter>
  );
}

export default App;
