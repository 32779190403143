import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import "./navbarmain.css";
import { Link } from "react-router-dom";
import CrmSettingsComp from "./CrmSettingsComp";
import UsersRoles from "./UsersRoles";
import { fetchCompanyDetails } from "../redux/slices/companySlice";

// Functional component for NavigationBar
export default function NavigationBar() {
  const dispatch = useDispatch();

  const [isShowSettings, setIsShowSettings] = useState(false);
  const [isShowUserRoles, setIsShowUserRoles] = useState(false);

  // Redux state ko access karo
  const { isLoading, data, isError } = useSelector((state) => state.company);

  if (isError) {
    console.log("Error While Fetching Details");
  }

  useEffect(() => {
    dispatch(fetchCompanyDetails());
  }, [dispatch]);

  return (
    <>
      <CrmSettingsComp
        isShowSettings={isShowSettings}
        setIsShowSettings={setIsShowSettings}
      />
      <UsersRoles
        isShowUserRoles={isShowUserRoles}
        setIsShowUserRoles={setIsShowUserRoles}
      />
      <section>
        {/* Main navigation div */}
        <div className="mainDiv_Navbar">
          {" "}
          {/* Main Navigation Links */}
          <ul>
            <li>
              <Link
                style={{
                  fontSize: "18px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <i className="fa-solid fa-city "></i>
                {isLoading ? (
                  <>loading....</>
                ) : (
                  <> {data ? data.companyName : "loading...."}</>
                )}{" "}
              </Link>
            </li>
            <div className="dvd"></div>

            <span>Main</span>
            <li>
              {/* Link to Dashboard */}
              <Link to={"/dashboard"}>
                <i className="fa-solid fa-chart-line"></i>Dashboard
              </Link>
            </li>
            <li>
              {/* Link to Leads */}
              <Link to={"/leads"}>
                <i className="fa-solid fa-user"></i>Leads
              </Link>
            </li>
            <li>
              {/* Link to Deals */}
              <Link to={"/deals"}>
                <i className="fa fa-handshake"></i>Deals
              </Link>
            </li>
            <li>
              {/* Link to Quotations */}
              <Link to={"/invoices"}>
                <i className="fa fa-file-invoice-dollar"></i>Invoices &
                Quotations
              </Link>
            </li>
            <li>
              {/* Link to Contacts */}
              <Link to={"/contacts"}>
                <i className="fa-solid fa-address-book"></i>Contacts
              </Link>
            </li>
            <li>
              {/* Link to Tasks */}
              <Link to={"/tasks"}>
                <i className="fa-solid fa-list-check"></i>Tasks
              </Link>
            </li>
          </ul>
          {/* Divider */}
          <div className="dvd"></div>
          <ul>
            <span>Website CMS</span>
            <li>
              <Link to={"/blogs"}>
                <i className="fa fa-newspaper"></i> Blogs
              </Link>
            </li>
            <li>
              <Link to={"/products"}>
                <i className="fa fa-box"></i> Products
              </Link>
            </li>
            <li>
              <Link to={"/website-leads"}>
                <i className="fa fa-handshake"></i>
                Leads
              </Link>
            </li>
            <li>
              <Link to={"/comingsoon"}>
                <i className="fa fa-file-alt"></i> Content
              </Link>
            </li>
          </ul>
          {/* Divider */}
          <div className="dvd"></div>
          {/* Settings Section */}
          <ul>
            {" "}
            <span>Settings</span>
            <li>
              {/* Link to User Roles */}
              <Link
                onClick={() => {
                  setIsShowUserRoles(true);
                }}
              >
                <i className="fa fa-users-cog"></i>Users & Roles
              </Link>
            </li>
            <li>
              {/* Link to Profiles */}
              <Link
                onClick={() => {
                  setIsShowSettings(true);
                }}
              >
                <i className="fa fa-user-cog"></i>Settings & Profiles
              </Link>
            </li>
          </ul>
          {/* Plan Details Section */}
          <div className="planDet">
            <span>Plan Details:</span>
            <span>
              <span style={{ color: "orangered" }}>Growth Starter</span> <br />
              Unlock New Heights
            </span>
          </div>
        </div>
      </section>
    </>
  );
}
