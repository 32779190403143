import React, { useState, useEffect } from "react";
import "./taskpg.css";
import axios from "axios";
import Taskview from "../Components/Taskview";
import { fetchUserData } from "../Data/UserDet"; // Import the function
import TaskAsignment from "../Components/TaskAsignment";

export default function Taskspage() {
  const [loading, setLoading] = useState(true);

  const [isViewTaskAssignment, setIsViewTaskAssignment] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [taskCls, setTskCls] = useState("taskView_div_hide");
  const [tasks, setTasks] = useState([]);
  const [leads, setLeads] = useState([]);
  const [deals, setDeals] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [dueDateFilter, setDueDateFilter] = useState("All"); // New state for due date filter
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedTime = now.toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "short",
      });
      setCurrentTime(formattedTime);
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }
        if (user) {
          const url =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_LEADS
              : process.env.REACT_APP_API_LEADS;

          const response_leads = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });

          const url1 =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_DEALS
              : process.env.REACT_APP_API_DEALS;

          const response2 = await axios.get(url1, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });
          const url2 =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_TASKS
              : process.env.REACT_APP_API_TASKS;

          const response = await axios.get(url2, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });
          setLeads(response_leads.data.leads);
          setDeals(response2.data.deals);

          setTasks(response.data.tasks);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const getUserAndFetchData = async () => {
      try {
        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get(process.env.REACT_APP_API_USERS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.users); // Assuming response includes a list of users
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (!users) {
      fetchUsers();
    }
    if (
      !leads ||
      leads.length <= 0 ||
      !deals ||
      deals.length <= 0 ||
      !tasks ||
      tasks.length <= 0
    ) {
      fetchData();
    }

    if (!user) {
      getUserAndFetchData();
    }
    if (user && users && leads && deals && tasks) {
      setLoading(false);
    }
  }, [user, users, leads, deals, tasks]);
  const getUserName = (userId) => {
    const user = users.find((user) => user._id === userId);
    return user ? user.userName : "Unknown"; // Fallback if user is not found
  };
  const formatDueDate = (date) => {
    const dueDate = new Date(date);
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;

    if (dueDate.toDateString() === today.toDateString()) return "Today";
    if (
      dueDate.toDateString() ===
      new Date(today.getTime() + oneDay).toDateString()
    )
      return "Tomorrow";
    if (
      dueDate.toDateString() ===
      new Date(today.getTime() - oneDay).toDateString()
    )
      return "Yesterday";

    return dueDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const handleCloseTaskView = () => {
    setTskCls("taskView_div_hide");
    setSelectedTaskId(null);
  };

  const isTaskMatchingDueDateFilter = (taskDate) => {
    const taskDueDate = new Date(taskDate);
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;

    switch (dueDateFilter) {
      case "Today":
        return taskDueDate.toDateString() === today.toDateString();
      case "Tomorrow":
        return (
          taskDueDate.toDateString() ===
          new Date(today.getTime() + oneDay).toDateString()
        );
      case "Overdue":
        return taskDueDate < today;
      case "All":
      default:
        return true;
    }
  };

  const filteredTasks = tasks.filter((task) => {
    const lead = leads.find((lead) => lead._id === task.lead_ID) || {};
    const deal = deals.find((deal) => deal._id === task.lead_ID) || {};
    const search = searchQuery.toLowerCase();
    return (
      (task.task.toLowerCase().includes(search) || // Task name
        user.userName.toLowerCase().includes(search) || // Assignee (hardcoded example)
        (lead.name && lead.name.toLowerCase().includes(search)) || // Lead name
        (deal.dealName && deal.dealName.toLowerCase().includes(search)) || // Lead name
        (deal.mobileNo && deal.mobileNo.toLowerCase().includes(search)) || // Lead name
        (lead.mobileNo && lead.mobileNo.toLowerCase().includes(search)) || // Requirement
        task.taskComment.toLowerCase().includes(search)) && // Comments
      isTaskMatchingDueDateFilter(task.taskDueDate) // Due date filter
    );
  });

  if ((tasks && leads) || deals) {
    return (
      <>
        <div className={taskCls}>
          <Taskview mode="new" onClose={handleCloseTaskView} />
        </div>
        <TaskAsignment
          isViewTaskAssignment={isViewTaskAssignment}
          setIsViewTaskAssignment={setIsViewTaskAssignment}
        />

        <section className="mainTaskSection">
          {/* Topbar */}
          <div className="topbar_main_tsk">
            <h1>✅ Unlock Your Potential, One Task at a Time</h1>
            <div className="topbar_right">
              <div className="date_time">
                <i className="fa-solid fa-clock"></i>{" "}
                <span>{currentTime ? currentTime : "loading..."}</span>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="mainDivision_TaskPage">
            <div className="topbar_second_tsk">
              <div style={{ display: "flex", gap: "10px" }}>
                {" "}
                {loading ? (
                  <>
                    <div>
                      <button>
                        Great button coming to you, please wait...
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setTskCls("taskView_div_show");
                      }}
                    >
                      {user ? (
                        user.role === "admin" ? (
                          <>
                            <i className="fa-solid fa-plus"></i> Add Task
                            (Private to you)
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-plus"></i> Add Task
                          </>
                        )
                      ) : (
                        <></>
                      )}
                    </button>
                    {user && user.role === "admin" && (
                      <button
                        onClick={() => {
                          setIsViewTaskAssignment(true);
                        }}
                      >
                        <i className="fa-solid fa-plus"></i> Assign a Task
                      </button>
                    )}
                  </>
                )}
              </div>

              <div className="search-div-tsk">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  type="text"
                  name="searchleads"
                  id="searchleads"
                  placeholder="Search from tasks"
                  className="search-input"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <select
                value={dueDateFilter}
                onChange={(e) => setDueDateFilter(e.target.value)} // Update due date filter
                className="due-date-filter"
              >
                <option value="All">All</option>
                <option value="Today">Today</option>
                <option value="Tomorrow">Tomorrow</option>
                <option value="Overdue">Overdue</option>
              </select>
            </div>

            <div className="main-tsk-div">
              <table>
                <tbody>
                  <tr>
                    <th>Task name</th>
                    <th>Assignee</th>
                    <th>Lead name</th>
                    <th>Mobile</th>
                    <th>Created on</th>
                    <th>Due on</th>
                    <th>Last updated on</th>
                    <th>Comment</th>
                  </tr>

                  {loading ? (
                    <>
                      {Array(10)
                        .fill(null)
                        .map((_, index) => (
                          <tr key={index}>
                            {Array(8)
                              .fill(null)
                              .map((_, colIndex) => (
                                <td key={colIndex}>
                                  <div className="skeleton skeleton-item"></div>
                                </td>
                              ))}
                          </tr>
                        ))}
                    </>
                  ) : (
                    <>
                      {filteredTasks
                        .sort(
                          (a, b) =>
                            new Date(b.taskDueDate) - new Date(a.taskDueDate)
                        )
                        .map((task) => {
                          let lead;
                          lead = leads.find(
                            (lead) => task.lead_ID === lead._id
                          );
                          if (lead === null || lead === undefined) {
                            lead = deals.find(
                              (deal) => task.lead_ID === deal._id
                            );
                          }

                          const tskCDate = new Date(task.creationDate);
                          const completionDate = new Date(task.completionDate);

                          const options = {
                            month: "short", // e.g., Dec
                            day: "2-digit", // e.g., 01
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true, // For AM/PM format
                          };

                          const formattedcrDate = tskCDate.toLocaleString(
                            "en-US",
                            options
                          );
                          const formattedCompletionDate =
                            completionDate.toLocaleString("en-US", options);

                          // if (lead === null || lead === undefined) {
                          //   return (
                          //     <>
                          //       <h1>No Lead</h1>
                          //     </>
                          //   );
                          // }
                          return (
                            <>
                              <tr
                                style={{
                                  color:
                                    task.taskStatus === "Completed"
                                      ? "rgb(120,120,120)"
                                      : "rgb(200,200,200)",
                                }}
                                onClick={() => {
                                  setSelectedTaskId(task._id);
                                }}
                              >
                                <td
                                  style={{
                                    gap: "7px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  {task.taskStatus === "Completed" ? (
                                    <i
                                      className="fa-solid fa-circle-check fa-lg"
                                      style={{ color: "#00c220" }}
                                    ></i>
                                  ) : task.taskStatus === "Pending" ? (
                                    <i
                                      className="fa-regular fa-circle-check fa-lg"
                                      style={{ color: "red" }}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa-regular fa-circle-check fa-lg"
                                      style={{ color: "#ccc" }}
                                    ></i>
                                  )}{" "}
                                  {task.task}
                                </td>
                                <td>{getUserName(task.userId)}</td>
                                <td>
                                  {lead
                                    ? lead.name
                                      ? lead.name
                                      : lead.dealName
                                    : "Not Available"}
                                </td>
                                <td>
                                  {" "}
                                  {lead
                                    ? lead.mobileNo
                                      ? lead.mobileNo
                                      : "No Contact"
                                    : "Not Available"}
                                </td>
                                <td>{formattedcrDate}</td>
                                <td>{formatDueDate(task.taskDueDate)}</td>
                                <td>{formattedCompletionDate}</td>
                                <td>{task.taskComment}</td>
                              </tr>
                              {selectedTaskId === task._id && (
                                <div className="taskView_div_show">
                                  <Taskview
                                    task={task._id}
                                    onClose={handleCloseTaskView}
                                    mode="view"
                                  />
                                </div>
                              )}
                            </>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        {/* Loading state */}
        <section className="mainTaskSection">
          <div className="topbar_main_tsk">
            <h1>✅ Unlock Your Potential, One Task at a Time</h1>
            <div className="topbar_right">
              <div className="date_time">
                <i className="fa-solid fa-clock"></i>{" "}
                <span>{currentTime ? currentTime : "loading..."}</span>
              </div>
            </div>
          </div>
          <div className="mainDivision_TaskPage">
            <div className="topbar_second_tsk">
              <button>
                <i className="fa-solid fa-plus"></i> Add Task
              </button>
              <div className="search-div-tsk">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  type="text"
                  name="searchleads"
                  id="searchleads"
                  placeholder="Search from tasks"
                  className="search-input"
                />
              </div>
              <i className="fa-solid fa-arrow-down-wide-short"></i>
            </div>

            <div className="main-tsk-div">
              <table>
                <tbody>
                  <tr>
                    <th>Task name</th>
                    <th>Assignee</th>
                    <th>Lead</th>
                    <th>Mobile</th>
                    <th>Due date</th>
                    <th>Comment</th>
                  </tr>
                  <tr>
                    <td>Loading...</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </>
    );
  }
}
